export default class AgentsPhoneNumber {
  static PHONE_NUMBERS = {
    ISA_LANDING: '8335811456',
    ISA_BYPASS_QUOTE_CONTROL: '8334512372',
    ISA_BYPASS_QUOTE_LANDING: '8333432381',
    ISA_LANDING_IS_ROOT_RIGHT_FOR_YOU: '8338266707',

    ISA_MARKET_REDUCTION: '8339063280',

    ISA_PREFILL: '8335501109',
    CSR_PREFILL: '8336801057',

    ISA_PROFILE: '8339912908',
    CSR_PROFILE: '8335831259',

    ISA_QUOTE: '8337000993',
    ISA_TEST_DRIVE_PACER: '8336561628',
    ISA_CHECKOUT: '8336591458',

    CSR_TRADITIONAL: '8336250167',
    CSR_IS_ROOT_RIGHT_FOR_YOU: '8335370659',

    ISA_EMBEDDED: '8333602743',

    ISA_JOINROOT: '8333600089',
    CSR_JOINROOT: '8335020990',

    ISA_JOINROOT_PAID_URL: '8335001362',
  }

  static formatAgentPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return null;
  }

  static phoneFullURL(phoneNumber) {
    return `tel:${phoneNumber}`;
  }
}
